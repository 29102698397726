<template>
  <div class="container-fluid post-single">
    <Post v-if="post" :post="post" />
  </div>
</template>

<script>
import Post from "@/components/Post.vue";
import wp from "@/plugins/wordpress.js";

export default {
  name: "SinglePost",
  components: {
    Post,
  },
  data: function () {
    return {
      post: null,
    };
  },
  created: async function () {
    this.post = await wp.posts().slug(this.$route.params.slug);
  },
};
</script>

<style lang="scss" scoped></style>
