<template>
  <div class="post" :class="{ 'is-fullscreen': isFullscreen }">
    <div class="columns">
      <article
        class="column article-content is-two-thirds"
        :class="{ 'p-6': !isFullscreen, 'p-0': isFullscreen }"
        @fullscreenchange="changeUIFullscreenMode"
        ref="fullscreen"
      >
        <div class="header-wrapper is-flex is-align-items-baseline">
          <div class="article-header is-flex-grow-1">
            <figure class="image is-64x64">
              <img
                :src="post.x_gravatar"
                class="is-rounded"
                alt="Author picture"
              />
            </figure>
            <div>
              <h1 class="title is-4" v-html="post.title.rendered"></h1>
              <div class="subtitle is-6">{{ post.date | moment("LLL") }}</div>
            </div>
          </div>
          <div class="toolbar">
            <div class="btn-slider is-justify-content-flex-end">
              <a
                v-if="totalPictures"
                class="btn-icon"
                :href="post.gallery[currentPicture]"
                download
              >
                <Icon name="Download"><CloudDownloadOutline /></Icon>
              </a>
              <a class="btn-icon" href="#" @click.prevent="toggleFullscreen">
                <Icon name="Fullscreen"><ExpandOutline /></Icon>
              </a>
              <div v-if="totalPictures" class="is-flex is-align-items-center">
                <a class="btn-icon" href="#" @click.prevent="previousPicture">
                  <Icon name="Previous"><ChevronBackOutline /></Icon>
                </a>
                {{ currentPicture + 1 }} / {{ totalPictures }}
                <a class="btn-icon" href="#" @click.prevent="nextPicture">
                  <Icon name="Next"><ChevronForwardOutline /></Icon>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="has-text-centered main-media">
          <img-comparison-slider
            v-if="totalPictures >= 2 && post.acf.compare_slider"
          >
            <img slot="first" :src="post.gallery[0]" />
            <img slot="second" :src="post.gallery[1]" />
            <Icon slot="handle" name="Slider" class="slider-handle"
              ><CodeOutline
            /></Icon>
          </img-comparison-slider>
          <template v-else>
            <carousel
              v-if="totalPictures > 1"
              :perPage="1"
              :paginationEnabled="false"
              @pageChange="currentPicture = $event"
              :navigate-to="nextSlide"
            >
              <slide v-for="(url, index) in post.gallery" :key="index">
                <img :src="url" />
              </slide>
            </carousel>
            <img v-else class="feature-image" :src="post.gallery[0]" />
          </template>
          <video
            v-if="post.acf.video"
            :id="`player-${post.slug}`"
            :src="post.acf.video.url"
            playsinline
            controls
          ></video>
        </div>
        <div class="tags-section">
          <br />
          <span
            v-for="cat in this.categories"
            :key="cat"
            class="tag is-primary is-medium has-text-weight-bold mr-6"
            >{{ cat }}</span
          >
        </div>
      </article>
      <div class="column side-content p-6">
        <div v-if="post.content.rendered">
          <p v-html="post.content.rendered"></p>
          <hr />
        </div>
        <h3 class="title is-3">Réactions</h3>
        <CommentForm :post-id="post.id" v-on:comment-added="getComments" />
        <br /><br />
        <Comment
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
          class="mb-3"
        />
        <br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import Comment from "@/components/Comment.vue";
import CommentForm from "@/components/CommentForm.vue";
import wp from "@/plugins/wordpress.js";

export default {
  name: "Post",
  props: {
    post: Object,
  },
  data: function () {
    return {
      currentPicture: 0,
      nextSlide: 0,
      comments: [],
      isFullscreen: false,
    };
  },
  components: {
    Carousel,
    Slide,
    Comment,
    CommentForm,
  },
  created: async function () {
    this.getComments();
    this.categories = this.post.x_categories.split(",");
    this.totalPictures = this.post.gallery.length;
  },
  methods: {
    getComments: async function () {
      this.comments = await wp.comments().get({ post: this.post.id });
    },
    nextPicture: function () {
      if (this.currentPicture >= this.totalPictures - 1) {
        this.nextSlide = 0;
      } else {
        this.nextSlide = this.currentPicture + 1;
      }
    },
    previousPicture: function () {
      if (this.currentPicture <= 0) {
        this.nextSlide = this.totalPictures;
      } else {
        this.nextSlide = this.currentPicture - 1;
      }
    },
    toggleFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        this.$refs.fullscreen.requestFullscreen();
      }
      this.changeUIFullscreenMode();
    },
    changeUIFullscreenMode() {
      this.isFullscreen = document.fullscreenElement;
    },
  },
};
</script>

<style scoped lang="scss">
.post {
  // @todo Find why there is an overflow and remove that !
  @include mobile {
    overflow-x: hidden;
  }
}

.article-content {
  background-color: var(--background-app-alt);

  .header-wrapper {
    @include mobile {
      flex-direction: column;
      .toolbar {
        margin-left: auto;
        margin-bottom: -45px;
        z-index: 99;
        border-radius: 20px;
      }
    }

    .article-header {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      @include mobile {
        width: 100%;
      }
      figure {
        margin: 0 1rem 0 2.5rem;
        @include mobile {
          margin: 0 1rem 0 0;
        }
      }
    }
  }
}

.is-fullscreen {
  .article-header,
  .tags-section {
    display: none !important;
  }

  .main-media {
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .feature-image,
  video,
  .VueCarousel-slide img {
    position: relative;
    max-height: 100vh;
  }

  .toolbar {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
    color: #ffffff;
    background-color: rgba($color: #000000, $alpha: 0.8);
    border-radius: 8px;
    padding: 3px 5px;
    .ionicon {
      color: #ffffff;
    }
  }
}

.btn-slider {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  ion-icon {
    font-size: 2rem;
    cursor: pointer;
    margin: auto 3px;
  }
}

.VueCarousel-slide {
  text-align: center;
  position: relative;
}

hr {
  border-bottom: 1px solid #cccccc;
  max-width: 40%;
  margin: 2rem auto;
}

.feature-image,
video,
img-comparison-slider,
.VueCarousel-slide img {
  border-radius: $default-radius;
  max-height: 800px;
  margin: auto;
  --handle-size: 60px;
}

video {
  width: 100%;
}
</style>
