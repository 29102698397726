<template>
  <div class="comment">
    <div class="columns is-mobile">
      <div class="column is-narrow px-1">
        <figure v-if="comment.author_avatar_urls" class="image is-48x48">
          <img class="is-rounded" :src="comment.author_avatar_urls[48]" />
        </figure>
      </div>
      <div class="column content">
        <h5 class="is-capitalized">{{ comment.author_name }}</h5>
        <div v-html="comment.content.rendered"></div>
        <div class="subtitle is-6">
          {{ comment.date | moment("from", "now") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Comment",
  props: {
    comment: Object,
  },
};
</script>

<style scoped lang="scss">
.content {
  border-radius: $default-radius;
  h5 {
    font-weight: bold;
    margin-bottom: 0.2rem;
  }
  .subtitle {
    font-size: 0.9rem;
  }
  .card-content {
    padding: 0.5rem;
  }
}
</style>
