<template>
  <div class="comment-form">
    <div class="field">
      <div class="control">
        <div class="comment-add">
          <input
            type="text"
            class="comment-input mb-0"
            v-model="comment"
            @keyup.enter="postComment"
            placeholder="💬 Laisser un commentaire"
          />
          <div class="comment-submit is-pulled-right" @click="postComment">
            <img src="../assets/loading.svg" alt="loading" v-show="loading" />
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              v-show="!loading"
            >
              <path
                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="error" class="notification is-danger">
      <button @click="error = null" class="delete"></button>
      {{ error }}
    </div>
  </div>
</template>

<script>
import { getUser } from "@/plugins/auth.js";
import wp from "@/plugins/wordpress.js";

export default {
  name: "CommentForm",
  props: ["postId"],
  data: function () {
    return {
      name: "",
      comment: "",
      editName: false,
      loading: false,
      error: "",
    };
  },
  created: async function () {
    this.user = getUser();
  },
  methods: {
    postComment: function () {
      let commentPayload = {
        post: this.postId,
        author_email: this.user.email,
        author_name: this.user.name,
        content: this.comment,
      };

      const self = this;
      this.loading = true;
      wp.comments()
        .create(commentPayload)
        .then(function () {
          self.$emit("comment-added", commentPayload);
          self.comment = "";
        })
        .catch(function (error) {
          console.log("Create comment Fail");
          console.log(error);
          self.error = error;
        })
        .finally(function () {
          self.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  display: inline-block;
  cursor: pointer;
  padding-left: 3px;
  width: 20px;
  height: 20px;
}
.comment-input {
  width: 100%;
  height: auto;
  color: var(--text-normal);
  background-color: var(--input-background);
  border: none;
  border-radius: $default-radius;
  box-shadow: none;
  padding: 1.5rem 1rem;
  word-break: break-word;

  &::placeholder {
    color: var(--text-subtitle);
  }
}
.comment-submit {
  svg,
  img {
    width: 32px;
    height: 32px;
    transform: rotate(90deg);
    padding: 5px;
    position: absolute;
    top: 18px;
    right: 10px;
  }
}

.columns {
  align-items: center;
}
</style>
